import Axios, { AxiosHeaders } from "axios"

import {
  NetworkUnauthorizedError,
  UnknownError,
  UnknownNetworkError,
} from "interfaces/error"

import logger from "./logger"
import { getSpearlyToken } from "./spearlyToken"

interface Headers {
  Authorization?: string
  Accept?: string
  X_CLIENT_UID?: string
  X_CLIENT_SECRET?: string
}

type ApiRequestMethodType = "get" | "put" | "post" | "delete"

// いずれ spearly-front に移動させるので無理やり型変換
const convertAxiosHeaders = (headers: Headers): AxiosHeaders => {
  return headers as AxiosHeaders
}

const generateAxiosClient = <TResponsePayloadDataType>(
  type: ApiRequestMethodType,
  url: string,
  headers: Headers,
  data?: unknown,
) => {
  switch (type) {
    case "delete":
    case "get":
      return Axios[type]<TResponsePayloadDataType>(url, {
        headers: convertAxiosHeaders(headers),
      })

    case "post":
    case "put":
      return Axios[type]<TResponsePayloadDataType>(url, data, {
        headers: convertAxiosHeaders(headers),
      })
  }
}

const executeApi = <TResponsePayloadDataType>(
  type: ApiRequestMethodType,
  url: string,
  headers: Headers,
  data?: unknown,
): Promise<TResponsePayloadDataType> =>
  new Promise((resolve, reject) => {
    const axios = generateAxiosClient<TResponsePayloadDataType>(
      type,
      url,
      headers,
      data,
    )

    axios
      .then((res) => {
        if (type === "post") {
          logger("added")
        } else if (type === "put") {
          logger("updated")
        }
        resolve(res.data)
      })
      .catch((err) => {
        if (Axios.isAxiosError(err) && err.response) {
          if (err.response.status === 401) {
            logger("unauthorized", err)
            reject(new NetworkUnauthorizedError("401 Unauthorized"))
          }
          if (err.response.status === 422) {
            logger("unprocessable entity", err)
            reject(new NetworkUnauthorizedError("422 Unprocessable Entity"))
          }
          logger("http err", err)
          reject(new UnknownNetworkError("Unknown network error"))
        } else {
          logger("unknown err", err)
          reject(new UnknownError("Unknown Error"))
        }
      })
  })

export const externalApi = <TResponsePayloadDataType>(
  type: ApiRequestMethodType,
  url: string,
  data?: unknown,
  options?: { token?: string },
): Promise<TResponsePayloadDataType> => {
  const headers: Headers = {
    Accept: "application/json",
  }

  if (options?.token) {
    headers.Authorization = `Bearer ${options.token}`
  }

  return executeApi(type, url, headers, data)
}

const api = <TResponsePayloadDataType>(
  type: ApiRequestMethodType,
  path: string,
  data?: unknown,
  options?: { login?: boolean; baseUrl?: string },
): Promise<TResponsePayloadDataType> => {
  const headers: Headers = {
    Accept: "application/vnd.spearly.v2+json",
    Authorization: `Bearer ${getSpearlyToken()}`,
  }

  if (options?.login) {
    delete headers.Authorization
  }

  const url = `${process.env.REACT_APP_API_URL}/${path}`

  return executeApi(type, url, headers, data)
}

export default api
